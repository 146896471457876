import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import '../styles/login.css';
import PopupMessage from '../components/PopupMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { loginUser } from '../apiService';

const LoginForm = () => {
  const [loginData, setLoginData] = useState({
    email: '',
    password: ''
  });
  const [showPassword, setShowPassword] = useState(false);
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false);
  const [isForgotPasswordPopupOpen, setIsForgotPasswordPopupOpen] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData({
      ...loginData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await loginUser(loginData);
      const token = data.token;
      console.log('Token:', token);
      login(token);
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      console.log('Decoded token:', decodedToken);
      if (decodedToken.is_admin) {
        navigate('/admin');
      } else {
        navigate('/');
      }
    } catch (error) {
      console.error('Error logging in:', error);
      if (error.response && error.response.status === 400) {
        setError('Incorrect email or password');
      } else {
        setError('Error logging in. Please try again later.');
      }
      setIsErrorPopupOpen(true);
    }
  };

  const closeErrorPopup = () => {
    setIsErrorPopupOpen(false);
    setError('');
  };

  const closeForgotPasswordPopup = () => {
    setIsForgotPasswordPopupOpen(false);
  };

  return (
    <div className="login-form-container">
      <h2 className="login-form-heading">Login</h2>
      <form onSubmit={handleSubmit} className="login-form">
        <div className="login-form-group">
          <label>Email *</label>
          <input type="email" name="email" value={loginData.email} onChange={handleChange} required />
        </div>
        <div className="login-form-group">
          <label>Password *</label>
          <div className="password-input-container">
            <input
              type={showPassword ? 'text' : 'password'}
              name="password"
              value={loginData.password}
              onChange={handleChange}
              required
            />
            <button
              type="button"
              className="password-toggle-button"
              onClick={() => setShowPassword(!showPassword)}
            >
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
            </button>
          </div>
        </div>
        <div className="login-form-group forgot-password">
          <span onClick={() => setIsForgotPasswordPopupOpen(true)}>Forgot Password?</span>
        </div>
        <div className="login-bt">
          <button type="submit" className="login-form-submit">Login</button>
        </div>
      </form>

      {isErrorPopupOpen && (
        <PopupMessage message={error} onClose={closeErrorPopup} />
      )}
      {isForgotPasswordPopupOpen && (
        <PopupMessage
          message="Please contact +92 323 8332105 to reset your password."
          onClose={closeForgotPasswordPopup}
        />
      )}
    </div>
  );
};

export default LoginForm;
