import React, { useState, useEffect } from 'react';
import '../../styles/admin.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import PopupMessage from '../PopupMessage';
import { fetchUsers, updateUserApproval } from '../../apiService';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [filter, setFilter] = useState('all');
  const [popupMessage, setPopupMessage] = useState('');
  const [isMP, setIsMP] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const getUsers = async () => {
      try {
        const data = await fetchUsers(token);
        setUsers(data);
      } catch (error) {
        setPopupMessage('Error fetching users');
        setIsMP(true);
      }
    };
    getUsers();
  }, []);

  const handleApprovalToggle = async (cnic) => {
    try {
      const token = localStorage.getItem('token');
      const user = users.find((user) => user.cnic === cnic);
      const newApprovalStatus = !user.approved;

      await updateUserApproval(cnic, newApprovalStatus, token);

      setUsers(users.map((user) =>
        user.cnic === cnic ? { ...user, approved: newApprovalStatus } : user
      ));
      setPopupMessage(newApprovalStatus ? 'User Approved Successfully' : 'User Disapproved Successfully');
      setIsMP(true);
    } catch (error) {
      setPopupMessage('Error updating approval status');
      setIsMP(true);
    }
  };

  const filteredUsers = users.filter((user) =>
    filter === 'all' ? true : filter === 'approved' ? user.approved : !user.approved
  );

  const openPopup = (user) => {
    setSelectedUser(user);
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setSelectedUser(null);
  };

  const closeMP = () => {
    setIsMP(false);
    setPopupMessage('');
  };

  return (
    <div className="ad-us-users">
      <div className='admins-bx'>
        <h2 className="ad-us-title">Users</h2>
        <div className="ad-us-filters">
          <button onClick={() => setFilter('all')} className="ad-us-filter-button">
            All Users
          </button>
          <button onClick={() => setFilter('approved')} className="ad-us-filter-button">
            Approved Users
          </button>
          <button onClick={() => setFilter('unapproved')} className="ad-us-filter-button">
            Unapproved Users
          </button>
        </div>
      </div>
      <div className="ad-us-user-list">
        <table className="ad-us-table">
          <thead>
            <tr>
              <th>Profile</th>
              <th>Name</th>
              <th>CNIC</th>
              <th>Contact No</th>
              <th>Payment Screenshot</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user) => (
              <tr key={user.cnic}>
                <td>
                  <div className='td-image-us'>
                    <img src={user.profile_image_url} alt={`${user.name}'s profile`} className="ad-us-table-img" />
                  </div>
                </td>
                <td>{user.name}</td>
                <td>{user.cnic}</td>
                <td>{user.contact_no}</td>
                <td>
                  <a href={user.payment_screenshot_url} target="_blank" rel="noopener noreferrer" className="ad-us-image-link">
                    View Payment Screenshot
                  </a>
                </td>
                <td>
                  <div className='ad-us-ac-bx'>
                    <button onClick={() => handleApprovalToggle(user.cnic)} className="ad-us-approve-button">
                      {user.approved ? 'Disapprove' : 'Approve'}
                    </button>
                    <FontAwesomeIcon icon={faEye} onClick={() => openPopup(user)} className="ad-us-eye-icon" />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isPopupOpen && selectedUser && (
        <div className="ad-us-popup-overlay" onClick={closePopup}>
          <div className="ad-us-popup" onClick={(e) => e.stopPropagation()}>
            <h3>{selectedUser.name}</h3>
            <p><strong>Email: </strong> {selectedUser.email}</p>
            <p><strong>Contact No: </strong> {selectedUser.contact_no}</p>
            <p><strong>Secondary Contact: </strong> {selectedUser.secondary_contact_no}</p>
            <p><strong>Relation: </strong> {selectedUser.relation_with_secondary_contact}</p>
            <p><strong>School: </strong> {selectedUser.school}</p>
            <p><strong>Grade: </strong> {selectedUser.grade}</p>
            <p><strong>Past Experience: </strong> {selectedUser.past_experience}</p>
            <p><strong>Soft Skills: </strong> {selectedUser.soft_skills}</p>
            <p><strong>Approved: </strong> {selectedUser.approved ? 'Yes' : 'No'}</p>
            <p><strong>Joined: </strong> {new Date(selectedUser.created_at).toDateString()}</p>
            <button onClick={closePopup} className="ad-us-close-button">Close</button>
          </div>
        </div>
      )}
      {isMP && (
        <PopupMessage message={popupMessage} onClose={closeMP} />
      )}
    </div>
  );
};

export default Users;
