import React, { useState, useEffect } from 'react';
import '../../styles/admin.css';
import { getNgos, createNgo, updateNgo, deleteNgo } from '../../apiService';

const Ngos = () => {
  const [ngos, setNgos] = useState([]);
  const [selectedNgo, setSelectedNgo] = useState(null);
  const [formVisible, setFormVisible] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchNgos = async () => {
      try {
        const data = await getNgos();
        setNgos(data);
      } catch (error) {
        setError('Failed to fetch NGOs.');
      }
    };
    fetchNgos();
  }, []);

  const handleEdit = (ngo) => {
    setSelectedNgo(ngo);
    setFormVisible(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this NGO?')) {
      try {
        await deleteNgo(id);
        setNgos(ngos.filter((ngo) => ngo.id !== id));
      } catch (error) {
        setError('Failed to delete NGO.');
      }
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('id', selectedNgo.id || '');
    formData.append('name', selectedNgo.name || '');
    formData.append('description', selectedNgo.description || '');
    formData.append('requirement', selectedNgo.requirement || '');
    formData.append('location', selectedNgo.location || '');
    formData.append('credit_hours', selectedNgo.credit_hours || '');
    formData.append('duration', selectedNgo.duration || '');
    if (selectedNgo.picture instanceof File) {
      formData.append('picture', selectedNgo.picture);
    }

    try {
      if (selectedNgo.id) {
        await updateNgo(formData);
      } else {
        await createNgo(formData);
      }
      const data = await getNgos(); // Refresh the NGO list
      setNgos(data);
      setFormVisible(false);
      setSelectedNgo(null);
    } catch (error) {
      setError('Failed to save NGO.');
    }
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'picture') {
      setSelectedNgo({ ...selectedNgo, [name]: files[0] });
    } else {
      setSelectedNgo({ ...selectedNgo, [name]: value });
    }
  };

  return (
    <div className="ad-ng-ngos">
      <h2 className="ad-ng-title">NGOs</h2>
      <button onClick={() => setFormVisible(true)} className="ad-ng-add-button">
        Add NGO
      </button>
      <div className="ad-ng-ngo-list">
        {ngos.map((ngo) => (
          <div key={ngo.id} className="ad-ng-ngo-card">
            <div className='ad-ngo-pic'>
              <img src={ngo.picture_url} alt={ngo.name} className="ad-ng-ngo-picture" />
            </div>
            <div className="ad-ng-ngo-info">
              <h3>{ngo.name}</h3>
              <p>Description: {ngo.description}</p>
              <p>Requirement: {ngo.requirement}</p>
              <p>Location: {ngo.location}</p>
              <p>Credit Hours: {ngo.credit_hours}</p>
              <p>Duration: {ngo.duration}</p>
            </div>
            <div className="ad-ng-ngo-actions">
              <button onClick={() => handleEdit(ngo)} className="ad-ng-edit-button">
                Edit
              </button>
              <button onClick={() => handleDelete(ngo.id)} className="ad-ng-delete-button">
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>
      {formVisible && (
        <div className="ad-ng-modal">
          <div className="ad-ng-modal-content">
            <div className="ad-ng-modal-header">
              <h2>{selectedNgo?.id ? 'Edit NGO' : 'Add NGO'}</h2>
              <button onClick={() => setFormVisible(false)} className="ad-ng-modal-close">
                &times;
              </button>
            </div>
            <form className="ad-ng-ngo-form" onSubmit={handleFormSubmit}>
              <label>
                Name:
                <input
                  type="text"
                  name="name"
                  value={selectedNgo?.name || ''}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                Description:
                <textarea
                  name="description"
                  value={selectedNgo?.description || ''}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                Requirement:
                <textarea
                  name="requirement"
                  value={selectedNgo?.requirement || ''}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                Location:
                <input
                  type="text"
                  name="location"
                  value={selectedNgo?.location || ''}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                Credit Hours:
                <input
                  type="number"
                  name="credit_hours"
                  value={selectedNgo?.credit_hours || ''}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                Duration:
                <input
                  type="text"
                  name="duration"
                  value={selectedNgo?.duration || ''}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                Picture:
                <input
                  type="file"
                  name="picture"
                  accept="image/*"
                  onChange={handleInputChange}
                />
              </label>
              <div className="ad-ng-form-buttons">
                <button type="submit" className="ad-ng-submit-button">
                  {selectedNgo?.id ? 'Update' : 'Add'}
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setFormVisible(false);
                    setSelectedNgo(null);
                  }}
                  className="ad-ng-cancel-button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {error && (
        <div className="ad-ng-error-message">
          {error}
        </div>
      )}
    </div>
  );
};

export default Ngos;
