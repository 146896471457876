  // AdminPortalPage.jsx

  import React, { useState,useContext } from 'react';
  import Sidebar from '../components/admin/sidebar'; // Assuming you have a Sidebar component
  import Dashboard from '../components/admin/dashboard'; // Example component for Dashboard
  import Admins from '../components/admin/admins'; // Example component for Admins
  import Users from '../components/admin/users'; // Example component for Users
  import Ngos from '../components/admin/ngos'; // Example component for Ngos
  import Internships from '../components/admin/internships'; // Example component for Internships
  import Galleries from '../components/admin/galleries'; // Example component for Galleries
  import Queries from '../components/admin/queries'; // Example component for Queries
  import Password from '../components/admin/password.js'; // Example component for Password
  import Consultancies from '../components/admin/consultancies'; // Example component for Consultancies
  import { AuthContext } from '../AuthContext';
  import '../styles/admin.css';
  import logo from '../assets/images/logo.jpeg';

  const AdminPortalPage = () => {
    const [selectedOption, setSelectedOption] = useState('dashboard'); // State to track selected option
    const { logout } = useContext(AuthContext);
    // Function to render selected component
    const renderComponent = () => {
      switch (selectedOption) {
        case 'dashboard':
          console.log(selectedOption);
          return <Dashboard />;
        case 'admins':
          console.log(selectedOption);
          return <Admins />;
        case 'users':
          return <Users />;
        case 'ngos':
          return <Ngos />;
        case 'internships':
          return <Internships />;
        case 'galleries':
          return <Galleries />;
          case 'queries':
            return <Queries />;
          case 'password':
            return <Password />;
          case 'consultancies':
            return <Consultancies />;
        default:
          return <Dashboard />;
      }
    };

    return (
      <div className="admin-page">
        <div className="admin-header">
          <div className='div-rig'>
            <img src={logo} alt="logo" className="logo" />
            <h1>Admin Portal</h1>
          </div>
          <div className='logout-div'>
            <button onClick={logout}>Logout</button>
          </div>
        </div>
        <div className="admin-content">
          <Sidebar onSelectOption={setSelectedOption} />
          <div className="main-content">
            {renderComponent()}
          </div>
        </div>
      </div>
    );
  }

  export default AdminPortalPage;
