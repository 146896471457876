import React, { useState, useEffect } from 'react';
import '../styles/profile.css';
import PopupMessage from '../components/PopupMessage';
import { fetchUserProfile, updateUserProfile, fetchUserInternships, updatePaymentScreenshot } from '../apiService';
import Navbar from '../components/navbar';
import Footer from '../components/footer';

const UserProfile = () => {
  const [profile, setProfile] = useState({});
  const [internships, setInternships] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [updatedProfile, setUpdatedProfile] = useState({});
  const [profileImage, setProfileImage] = useState(null);
  const [paymentScreenshot, setPaymentScreenshot] = useState(null);
  const [popupMessage, setPopupMessage] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    fetchUserProfile(token)
      .then(data => {
        setProfile(data);
        setUpdatedProfile(data);
      })
      .catch(error => {
        setPopupMessage('Error fetching user profile');
        setIsPopupOpen(true);
      });

    fetchUserInternships(token)
      .then(data => setInternships(data))
      .catch(error => {
        setPopupMessage('Error fetching user internships');
        setIsPopupOpen(true);
      });
  }, []);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      if (name === 'profile_image') {
        setProfileImage(files[0]);
      } else if (name === 'payment_ss') {
        setPaymentScreenshot(files[0]);
      }
    } else {
      setUpdatedProfile(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleProfileSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.keys(updatedProfile).forEach(key => {
      formData.append(key, updatedProfile[key]);
    });
    if (profileImage) {
      formData.append('profile_image', profileImage);
    }

    try {
      const token = localStorage.getItem('token');
      await updateUserProfile(formData, token);
      setPopupMessage('Profile Updated Successfully');
      setIsPopupOpen(true);
      const updatedData = await fetchUserProfile(token);
      setProfile(updatedData);
      setUpdatedProfile(updatedData);
      setIsEditing(false);
    } catch (error) {
      setPopupMessage('Error updating profile');
      setIsPopupOpen(true);
    }
  };

  const handlePaymentScreenshotSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (paymentScreenshot) {
      formData.append('payment_ss', paymentScreenshot);
    }

    try {
      const token = localStorage.getItem('token');
      await updatePaymentScreenshot(formData, token);
      setPopupMessage('Payment Screenshot Updated Successfully');
      setIsPopupOpen(true);
      const updatedData = await fetchUserProfile(token);
      setProfile(updatedData);
    } catch (error) {
      setPopupMessage('Error updating payment screenshot');
      setIsPopupOpen(true);
    }
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setPopupMessage('');
  };

  return (
    <>
      <Navbar />
      <div className="profile-container">
        <h2 className="profile-title">User Profile</h2>
        <div className="profile-content">
          <div className="profile-section profile-details-section">
            {isEditing ? (
              <form onSubmit={handleProfileSubmit} className="profile-form" encType='multipart/form-data'>
                {['name', 'gender', 'contact_no', 'email', 'secondary_contact_no', 'relation_with_secondary_contact', 'school', 'grade'].map(field => (
                  <div key={field} className="profile-form-group">
                    <label>{field.replace(/_/g, ' ').toUpperCase()}</label>
                    <input
                      type={field === 'email' ? 'email' : 'text'}
                      name={field}
                      value={updatedProfile[field] || ''}
                      onChange={handleInputChange}
                    />
                  </div>
                ))}
                <div className="profile-form-group">
                  <label>Past Experience</label>
                  <textarea name="past_experience" value={updatedProfile.past_experience || ''} onChange={handleInputChange}></textarea>
                </div>
                <div className="profile-form-group">
                  <label>Soft Skills</label>
                  <textarea name="soft_skills" value={updatedProfile.soft_skills || ''} onChange={handleInputChange}></textarea>
                </div>
                <div className="profile-form-group">
                  <label>Profile Image</label>
                  <input type="file" name="profile_image" onChange={handleInputChange} />
                </div>
                <div className="profile-form-actions">
                  <button type="submit" className="profile-submit-button">Save</button>
                  <button type="button" className="profile-cancel-button" onClick={() => setIsEditing(false)}>Cancel</button>
                </div>
              </form>
            ) : (
              <div className="profile-details">
                <div className="profile-image">
                  <img src={profile.profile_img_url} alt="Profile" />
                </div>
                <div className="profile-info">
                  <table className="profile-info-table">
                    <tbody>
                      {Object.entries(profile).map(([key, value]) => (
                        key !== 'profile_img_url' && (
                          <tr key={key}>
                            <td><strong>{key.replace(/_/g, ' ').toUpperCase()}:</strong></td>
                            <td>{value}</td>
                          </tr>
                        )
                      ))}
                    </tbody>
                  </table>
                  <div className='edit-bt-pr'>
                    <button className="profile-edit-button" onClick={() => setIsEditing(true)}>Edit Profile</button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="profile-section profile-internships-section">
            <h3>Applied Internships</h3>
            <table className="profile-internships-table">
              <thead>
                <tr>
                  <th>NGO</th>
                  <th>Applied On</th>
                </tr>
              </thead>
              <tbody>
                {internships.map((internship, index) => (
                  <tr key={index}>
                    <td>{internship.ngo_name}</td>
                    <td>{new Date(internship.created_at).toDateString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="profile-section profile-payment-screenshot-section">
            <h3>Update Payment </h3>
            <form onSubmit={handlePaymentScreenshotSubmit} className="payment-screenshot-form" encType='multipart/form-data'>
              <div className="form-group">
                <label>Payment Screenshot</label>
                <input type="file" name="payment_ss" onChange={handleInputChange} />
              </div>
              <button type="submit" className="payment-screenshot-submit-button">Update Screenshot</button>
            </form>
          </div>
        </div>
      </div>
      <Footer />
      {isPopupOpen && (
        <PopupMessage message={popupMessage} onClose={closePopup} />
      )}
    </>
  );
};

export default UserProfile;
