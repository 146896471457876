import React from 'react';
import Navbar from '../components/navbar';
import Hero from '../components/hero';
import Goals from '../components/goals';
import Opportunities from '../components/opportunities';
import About from '../components/aboutus';
import Team from '../components/team';
import Partners from '../components/partner';
import Footer from '../components/footer';
const HomePage = () => {
    return (
        <div>
        <Navbar />
        <Hero />
        <Goals />
        <Opportunities />
        <About />
        <Team />
        <Partners />
        <Footer />
    </div>
    );
};

export default HomePage;