import React from "react";
import "../styles/homePage.css";

const Goals = () => {
    return (
        <section className="goals-section">
            <h2 className="goals-title">Our Goals</h2>
            <div className="goals-list">
                <div className="goal-item">
                    <h3 className="goal-title">Equal opportunity</h3>
                    <div className="hm-op-tst">
                       <p className="goal-text">Khwaab aims to ensure that every student, regardless of their background, has the same access to resources and guidance needed to succeed academically.</p>
                    </div>
                </div>
                <div className="goal-item">
                    <h3 className="goal-title">Success</h3>
                    <div className="hm-op-tst">
                        <p className="goal-text">Khwaab is centred on equipping students with the resources and support they need to achieve their highest potential in both academics and life.</p>
                    </div>
                </div>
                <div className="goal-item">
                    <h3 className="goal-title">Guidance</h3>
                    <div className="hm-op-tst">
                      <p className="goal-text">The goal of Khwaab is to provide students with limited access to guidance the opportunities they deserve, addressing the urgent need for proper advice in society.</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Goals;