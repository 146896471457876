import React, { useEffect, useState, useContext } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import $ from 'jquery';
import { AuthContext } from '../AuthContext'; // Import the AuthContext
import logo from '../assets/images/logo.jpeg';
import '../styles/navbar.css';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { user, logout } = useContext(AuthContext); // Use the AuthContext

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    var str = '#len'; // increment by 1 up to 1-elements
    $(document).ready(function () {
      var i, stop;
      i = 1;
      stop = 5; // num elements
      setInterval(function () {
        if (i > stop) {
          return;
        }
        $('#len' + i++).toggleClass('bounce');
      }, 500);
    });
  }, []);

  return (
    <div className="container-fluid">
      <div className='nav-logo'>
        <img src={logo} alt="logo" />
        <li><a id="len1" className="hoverable" href="/">Home</a></li>
      </div>
      <div className={`ul-list ${isOpen ? 'open' : ''}`}>
        <ul className="nav navbar-nav">
          <li><a id="len2" className="hoverable" href="/ngos">Volunteer Now</a></li>
          <li><a id="len3" className="hoverable" href="/consult">Consultancy</a></li>
          <li><a id="len4" className="hoverable" href="/Gallery">Gallery</a></li>
          <li><a id="len5" className="hoverable" href="/contact">Contact</a></li>
          {user ? (
            <>
              <li><a id="len6" className="hoverable" href="/profile">Profile</a></li>
              <li><a id="len7" className="hoverable" onClick={logout} href="#">Logout</a></li>
            </>
          ) : (
            <li><a id="len6" className="hoverable" href="/register">Register</a></li>
          )}
        </ul>
      </div>
      <div className="menu-icon" onClick={toggleMenu}>
        {isOpen ? <FaTimes /> : <FaBars />}
      </div>
    </div>
  );
};

export default Navbar;
