import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/registeration.css';
import RegistrationForm from '../components/register';
import LoginForm from '../components/login';

const RegisterPage = () => {
  const [selectedForm, setSelectedForm] = useState('login');
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <div className="register-page-container">
      <button className="back-button" onClick={handleBackClick}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left-circle" viewBox="0 0 16 16">
          <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"/>
        </svg>
      </button>
      <div className="form-selection-container">
        <button
          className={selectedForm === 'login' ? 'active' : ''}
          onClick={() => setSelectedForm('login')}
        >
          Login
        </button>
        <button
          className={selectedForm === 'register' ? 'active' : ''}
          onClick={() => setSelectedForm('register')}
        >
          Register
        </button>
      </div>
      <div className="form-wrapper">
        {selectedForm === 'login' && <LoginForm />}
        {selectedForm === 'register' && <RegistrationForm />}
      </div>
    </div>
  );
};

export default RegisterPage;
