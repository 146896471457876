import React, { useState, useEffect } from "react";
import "../styles/homePage.css";
import { useSwipeable } from "react-swipeable";
import { getNgos } from '../apiService';

const Partners = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [visiblePartners, setVisiblePartners] = useState(1);
    const [isMobile, setIsMobile] = useState(false);
    const [ngos, setNgos] = useState([]);

    useEffect(() => {
        const updateVisiblePartners = () => {
            const screenWidth = window.innerWidth;
            if (screenWidth >= 1024) {
                setVisiblePartners(3);
                setIsMobile(false);
            } else if (screenWidth >= 768) {
                setVisiblePartners(2);
                setIsMobile(false);
            } else {
                setVisiblePartners(1);
                setIsMobile(true);
            }
        };

        const fetchNgosData = async () => {
            try {
                const data = await getNgos();
                setNgos(data);
            } catch (error) {
                console.error('Error fetching NGOs:', error);
            }
        };

        fetchNgosData();
        updateVisiblePartners();
        window.addEventListener("resize", updateVisiblePartners);

        return () => window.removeEventListener("resize", updateVisiblePartners);
    }, []);

    const prevPartner = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? ngos.length - visiblePartners : prevIndex - 1
        );
    };

    const nextPartner = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex >= ngos.length - visiblePartners ? 0 : prevIndex + 1
        );
    };

    const handlers = useSwipeable({
        onSwipedLeft: nextPartner,
        onSwipedRight: prevPartner,
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
    });

    return (
        <section className="partner-section" id="partners">
            <h2 className="partner-heading">Our Partners</h2>
            <div className="partner-slider" {...handlers}>
                {!isMobile && <button className="partner-prev-button" onClick={prevPartner}>←</button>}
                <div className="partner-members-container">
                    {ngos.slice(currentIndex, currentIndex + visiblePartners).map((ngo, index) => (
                        <div className="partner-member" key={ngo.cnic || index}>
                            <img src={ngo.picture_url} alt={ngo.name} className="partner-member-image" />
                            <h3 className="partner-member-name">{ngo.name}</h3>
                        </div>
                    ))}
                </div>
                {!isMobile && <button className="partner-next-button" onClick={nextPartner}>→</button>}
            </div>
            {isMobile && <div className="swipe-text">Swipe left or right to see other partners</div>}
        </section>
    );
};

export default Partners;
