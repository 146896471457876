import React, { useState, useEffect } from 'react';
import '../../styles/admin.css';
import { fetchConsultancies, deleteConsultancy } from '../../apiService';

const Consultancies = () => {
  const [consultancies, setConsultancies] = useState([]);
  const [filter, setFilter] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const getConsultancies = async () => {
      try {
        const data = await fetchConsultancies();
        setConsultancies(data);
      } catch (error) {
        setError('Failed to fetch consultancies.');
      }
    };

    getConsultancies();
  }, []);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredConsultancies = consultancies.filter(consultancy =>
    (consultancy.name || '').toLowerCase().includes(filter.toLowerCase()) ||
    (consultancy.cnic || '').toLowerCase().includes(filter.toLowerCase())
  );

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this consultancy?')) {
      try {
        await deleteConsultancy(id);
        setConsultancies(consultancies.filter(consultancy => consultancy.consultancy_id !== id));
      } catch (error) {
        setError('Failed to delete consultancy.');
      }
    }
  };

  return (
    <div className="ad-it-consultancies">
      <div className='admins-bx'> 
        <h2 className="ad-it-title">Consultancies</h2>
      </div>
      <div className="ad-it-filter">
        <label htmlFor="filter">Filter by Name or CNIC:</label>
        <input
          type="text"
          id="filter"
          value={filter}
          onChange={handleFilterChange}
          placeholder="Enter Name or CNIC"
        />
      </div>
      {error && <div className="ad-it-error-message">{error}</div>}
      <table className="ad-it-table">
        <thead>
          <tr>
            <th>Applicant</th>
            <th>Contact No</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredConsultancies.length > 0 ? (
            filteredConsultancies.map((consultancy) => (
              <tr key={consultancy.consultancy_id}>
                <td>{consultancy.name}</td>
                <td>{consultancy.contact}</td>
                <td>
                  <button onClick={() => handleDelete(consultancy.consultancy_id)} className="ad-qr-delete-button">
                    Delete
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3">No consultancies found.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Consultancies;
