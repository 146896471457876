import React from "react";
import "../styles/homePage.css";

const Hero = () => {
    return (
        <div className="hero-section">
            <div className="hero-content">
                <h1 className="hero-title">Here’s what comprises of your journey with Khwaab.</h1>
                <p className="hero-description">From being connected to the best volunteer opportunities to suitable internship options, we’ve got it all covered. This consultancy platform will pave your way in best possible regard, offering a myriad of exclusive services that will enhance your profile.</p>
                <button className="hero-button"><a className="hero-link" href="#opportunities">Explore Opportunities</a></button>
            </div>
        </div>
    );
};

export default Hero;
