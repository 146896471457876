import { type } from "jquery";

const Tip = {
  "General Tips": {
    description: [
      { type: "paragraph", content: "The coherence of one's application is among its most crucial components while applying to colleges. Cohesiveness is defined as the presence of a unifying theme throughout your application. It is crucial that you consider your identity, what kind of profile your application is forming, and whether your motivations make sense while you are creating a cohesive application. Cohesiveness contributes directly to the storytelling element, the concept of showing rather than telling, and subtly provides reasons for your desire to learn a certain subject. Some of your supplements should be based on your activities, and they should address concepts mentioned in your ambitions and recommendations. Emerging themes should form a coherent narrative. For instance, if you wish to study biology, you should be able to explain your interest in the subject with supplements and activities that further explore your enthusiasm." },
      { type: "break", content: "<br></br>" },
      { type: "heading", content: "Steer Away from Taboos" },
      { type: "paragraph", content: "Admissions counselors often encourage you to be truthful about your past experiences. On the other hand, there are several subjects that we advise students to avoid. College admissions committees view certain subjects as off-limits. For instance, admissions committees may get immediately turned off by subjects like drugs, sex, criminality, and violence, especially when they are discussed in graphic ways. We understand that this may take away from your ability to share your experiences, but writing on certain subjects in-depth or even just briefly could hurt your chances of getting accepted." },
      { type: "break", content: "<br></br>" },
      { type: "heading", content: "College selection" },
      { type: "paragraph", content: "Navigating the process of creating a college list can be daunting. While the well-known universities are on everyone’s radar, what about the lesser-known schools? And how can you assess your chances of acceptance? There’s no strict rule on the number of colleges to apply to, but it’s crucial to emphasize quality over quantity. Instead of asking, 'How many colleges should I apply to?' focus on 'How many applications can I make truly stand out?' This shift in mindset will increase your chances of success when decisions start rolling in." }
    ]
  },

  "College List": {
    description: [
      { type: "paragraph", content: "Academic credentials often play a pivotal role in college admissions. As you explore potential schools, pay close attention to their average SAT scores and GPAs. If your stats fall below their averages, that school may be a reach; if they’re significantly higher, it could be a safety. Kollegio’s software can help classify schools based on your academic history, but remember, meeting the academic criteria only means your application might get noticed. A well-rounded profile is key, especially for highly competitive institutions." },
      { type: "break", content: "<br></br>" },
      { type: "heading", content: "Structuring Your College List" },
      { type: "paragraph", content: "Typically, colleges are grouped into three categories: safeties, targets, and reaches. Which category a school falls into can vary depending on your profile, but certain schools, like Ivy Leagues, are almost always considered reaches. Deciding how many schools to apply to from each category can be tricky. For instance, if financial aid is a major concern, you might want to include more safety schools. Conversely, if your primary goal is to get into a highly selective school, your list may lean more towards reach schools. Generally, a balanced approach with around 40% safeties, 40% targets, and 20% reaches is advisable. If you're unsure, starting with this ratio can provide a solid foundation for building your list." }
    ]
  },

  "Essays": {
    description: [
      { type: "paragraph", content: "Short essays, typically ranging from 5 to 50 words, can be challenging to write, especially when you have a lot to say on a subject. To manage this, start by writing freely without worrying about the word count. Once you have your draft, go through it carefully to remove any unnecessary words or sentences that don't add value. Always ask yourself, 'Can I convey this idea more concisely?'" },
      { type: "break", content: "<br></br>" },
      { type: "heading", content: "Addressing 'Why Major?' and 'Why University?' Essays" },
      { type: "paragraph", content: "Two common types of supplemental essay prompts are the 'Why Major?' and 'Why University?' questions. Though schools may not use these exact phrases, you can usually recognize when a prompt is asking about these topics. For the 'Why Major?' essay, depending on the word limit, it's crucial to reference your activities and experiences mentioned elsewhere in your application to explain why you're passionate about the subject. Discuss personal experiences, influential teachers, or moments that sparked your interest. You can also highlight what you’ve done inside and outside the classroom to explore this field and what you hope to achieve in the future." },
      { type: "break", content: "<br></br>" },
      { type: "heading", content: "Reflective and Personal Essays" },
      { type: "paragraph", content: "Essays should generally be reflective and personal. A reflective essay highlights what you've learned from an experience and how it has shaped your perspective. Even simple topics like extracurricular activities can include personal reflection. For example, consider what you learned from being on a sports team, how you felt overcoming challenges, or what leadership meant to you." },
      { type: "break", content: "<br></br>" },
      { type: "heading", content: "The Common App Essay" },
      { type: "paragraph", content: "The Common App essay is a critical component of your application, often sent to multiple schools. While students may stress about finding a unique topic, the key is more about execution than originality. With so many students applying each year, it's difficult to choose a topic that no one else has thought of. Instead, focus on telling your story in a way that reflects your unique perspective and personal style." }
    ]
  },

  "Activities": {
    description: [
      { type: "paragraph", content: "When it comes to college applications, especially for competitive schools, standing out isn't just about good grades. Colleges are looking to build a diverse and well-rounded class, rather than selecting students who are simply involved in a bit of everything. Elite institutions often have specific needs—like a set number of athletes, musicians, or debate champions—so spreading yourself too thin across many activities might not make the impression you're hoping for." },
      { type: "break", content: "<br></br>" },
      { type: "heading", content: "Developing Key Areas of Focus" },
      { type: "paragraph", content: "Concentrating your extracurricular efforts in one or two key areas can significantly strengthen your application. These areas, or 'peaks,' represent your primary passions and expertise. A peak generally includes 3-4 activities that are interconnected, such as those related to Sports, STEM, or the Arts. By focusing on specific areas, you showcase your depth of commitment and allow the admissions committee to see where you truly excel." },
      { type: "break", content: "<br></br>" },
      { type: "heading", content: "Balancing Depth with Breadth" },
      { type: "paragraph", content: "While it’s important to have well-developed peaks, showing some breadth in your extracurricular activities can also be beneficial. Breadth refers to having a few activities outside your main areas of focus that demonstrate intellectual curiosity and a willingness to explore new fields. For instance, if your primary focus is on the humanities, you might consider participating in a coding workshop or a science fair. This not only adds dimension to your profile but also shows colleges that you have a wide range of interests." },
      { type: "break", content: "<br></br>" },
      { type: "heading", content: "The Importance of Community Service" },
      { type: "paragraph", content: "Community service is a fundamental component of a well-rounded college application. Colleges highly value students who are not only academic achievers but also active, compassionate contributors to their communities. Engaging in service activities is essential and doesn't have to be overly complex—whether it's volunteering at a local charity, organizing a neighborhood cleanup, or mentoring younger students, each experience matters." },
      { type: "break", content: "<br></br>" },
      { type: "heading", content: "Getting Started with Extracurriculars" },
      { type: "paragraph", content: "Starting out with extracurricular activities can feel overwhelming, especially if you’re unsure where to begin. However, the sooner you start exploring, the sooner you’ll discover where your strengths and interests lie. Begin by speaking with your guidance counselor or teachers about opportunities at your school. Look around your community for volunteer projects or clubs that pique your interest. If you’re feeling ambitious, consider starting your own initiative—whether it’s a small community project or a club focused on an issue you care about." }
    ]
  },

  "Awards And Honors": {
    description: [
      { type: "paragraph", content: "The Awards and Honors section of college applications can be challenging for many students. It's an area where applicants often either overlook their achievements or misinterpret what qualifies as noteworthy. However, most students have accomplishments that fit into this category, even if they initially think their achievements are too minor or irrelevant to mention.\n" },
      { type: "break", content: "<br></br>" },
      { type: "heading", content: "Embracing a Range of Achievements" },
      { type: "paragraph", content: "While this section primarily seeks to highlight academic achievements, it's important to remember that non-academic accomplishments can also be included. Admissions committees frequently value a diverse range of achievements, as this reflects a well-rounded applicant. Beyond academic awards, you can list recognitions in areas like debate, business competitions, or community service. These achievements are just as relevant and contribute to a holistic view of your capabilities." },
      { type: "break", content: "<br></br>" },
      { type: "heading", content: "Recognizing the Value of All Achievements" },
      { type: "paragraph", content: "Students often overlook the significance of their achievements, assuming that only grand accomplishments are worth mentioning. However, even minor recognitions can provide insight into your character and interests. For example, winning a local art contest or receiving a certificate for community service can demonstrate your dedication and passion. Remember, the goal is to present a well-rounded picture of yourself, so don't discount any of your achievements." },
    ],
  },
  "Recommendations": {
    description: [
      {type:"heading",content:'How to Approach a Recommender'},
      {type:"paragraph",content:`When seeking a recommendation for your college application, it's essential to approach potential recommenders with politeness and respect. Whether you're asking your college counsellor or a teacher, it's important to show that you are earnest in your request. Make sure to listen attentively to any feedback they provide and do your best to leave a positive impression. Remember, your recommender is doing you a favor, and they may be writing recommendations for several students, so being both polite and persistent is key.`},
      {type:"break",content:'<br></br>'},
      {type:"paragraph",content:`When setting up meetings, be considerate and flexible in scheduling, demonstrating that you take the college application process seriously. This shows the recommender how much you value their support in your application.`},
      {type:"break",content:'<br></br>'},
      {type:"heading",content:'Securing a Strong Recommendation'},
      {type:"paragraph",content:`Choosing the right person to write your recommendation is crucial. Ask yourself: Does this person know me well? Are they a strong writer? If they are a teacher, do they teach a subject that aligns with my intended major? These are important factors to consider when selecting who to approach.`},
      {type:"break",content:'<br></br>'},
      {type:"paragraph",content:`Even if a recommender has known you for years, they may not be fully aware of all your achievements, both in and out of school. It can be helpful to provide them with an outline of the activities, awards, and accomplishments you would like them to highlight in their letter. Some schools may provide a self-assessment form for you to fill out, but if not, it’s a good idea to create one yourself to guide your recommender.`},
      {type:"break",content:'<br></br>'},
      {type:"paragraph",content:`Another effective strategy is to emphasise different aspects of your profile to different recommenders. By doing so, you can ensure that various facets of your achievements are covered across multiple letters. If you have a particularly significant activity or accomplishment, multiple recommenders can address it from different perspectives, providing a well-rounded view of your contributions.`},
      {type:"break",content:'<br></br>'},
      {type:"heading",content:'Following Up on Recommendations'},
      {type:"paragraph",content:`Some colleges allow applicants to submit additional recommendations beyond the required ones. This could range from one extra letter to several, depending on the school. It’s wise to have a few additional recommenders in mind who can vouch for you if needed`},
      {type:"break",content:'<br></br>'},
      {type:"paragraph",content:`When choosing these additional recommenders, prioritise those who know you well and can provide a detailed and positive assessment of your abilities. People who have taught you, supervised you, or worked closely with you are often the best candidates to ask. By preparing in advance, you’ll be ready to take advantage of any additional recommendation opportunities that arise during the application process`},

    ]},
  "Additional Info": {
    description: [
      { type: "paragraph", content: "This area is often ignored or misinterpreted by many students applying to colleges, which is very unfortunate. If used correctly, the add info section can be a significant boost to a student’s application." },
      { type: "break", content: "<br></br>" },
      { type: "heading", content: "Description outline" },
      { type: "paragraph", content: "The add info section has a limit of 650 words, which many incorrectly interpret as a place to write an additional personal essay. Instead, students should focus on creating an outline of what additional information they would like to inform the admissions committee about regarding their application, such as activities, grade fluctuation, etc. A student should not feel pressure to fill this list up to 650 words but having some words down for this section is important for a competitive application." },
      { type: "break", content: "<br></br>" },
      { type: "heading", content: "Proof" },
      { type: "paragraph", content: "Another facet that this section provides is a place where students can provide proof for their activities and awards. Students can provide links to websites, Instagram pages, and other online sites where their work is documented. This is important to underscore the impact and reach of a student’s activities as well as verify to the admissions committee that the activity is as impactful as outlined in the activity list." }
    ]
  }
};

export default Tip;
