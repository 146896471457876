import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/register.css';
import PopupMessage from '../components/PopupMessage';
import { registerUser } from '../apiService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
const RegistrationForm = () => {
  const [regData, setRegData] = useState({
    name: '',
    gender: '',
    contact_no: '',
    secondary_contact_no: '',
    relation_with_secondary_contact: '',
    email: '',
    cnic: '',
    school: '',
    grade: '',
    past_experience: '',
    soft_skills: '',
    profileImage: null,
    profileImageName: '', // To store the file name
    payment_ss: null,
    payment_ssName: '', // To store the file name
    password: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setRegData((prevData) => ({
        ...prevData,
        [name]: files[0],
        [`${name}Name`]: files[0].name, // Store the file name in the corresponding state property
      }));
    } else {
      setRegData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.keys(regData).forEach((key) => {
      if (key !== 'profileImageName' && key !== 'payment_ssName') {
        formData.append(key, regData[key]);
      }
    });

    if (!regData.profileImage) {
      setError('Please upload a profile picture');
      setIsErrorPopupOpen(true);
      return;
    }
    if (!regData.payment_ss) {
      setError('Please upload a payment screen-shot');
      setIsErrorPopupOpen(true);
      return;
    }

    try {
      const response = await registerUser(formData);

      if (response.status !== 200) {
        setError(response.data.message);
        setIsErrorPopupOpen(true);
        return;
      }

      setError('Registration successful - Please login to continue');
    } catch (error) {
      console.error('Error during registration:', error);
      setError(error.response?.data?.message || 'An error occurred during registration');
      setIsErrorPopupOpen(true);
    }
  };

  const handleFileButtonClick = (id) => {
    document.getElementById(id).click();
  };

  const closeErrorPopup = () => {
    setIsErrorPopupOpen(false);
    setError('');
    window.location.reload();

  };

  return (
    <div className="registration-form-container">
      <h2 className="registration-form-heading">Registration Form</h2>
      <form onSubmit={handleSubmit} className="registration-form">
        <div className="registration-form-group">
          <label>Name *</label>
          <input type="text" name="name" value={regData.name} onChange={handleChange} required />
        </div>

        <div className="registration-form-group">
          <label>Gender *</label>
          <select name="gender" value={regData.gender} onChange={handleChange} required>
            <option value="">Select Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
        </div>

        <div className="registration-form-group">
          <label>Phone Number *</label>
          <input type="tel" name="contact_no" value={regData.contact_no} onChange={handleChange} required />
        </div>

        <div className="registration-form-group rg-pay">
          <label>Your Fresh Picture</label>
          <input type="file" id="profileImageInput" name="profileImage" accept="image/*" onChange={handleChange} style={{ display: 'none' }} />
          <div className="register-btn fresh-pc">
            <div>
              <button type="button" className="custom-file-button" onClick={() => handleFileButtonClick('profileImageInput')} required>Choose File</button>
            </div>
            {regData.profileImageName && <span className="file-name">Selected file: {regData.profileImageName}</span>}
          </div>
        </div>

        <div className="registration-form-group">
          <label>Secondary Emergency Phone Number *</label>
          <input type="tel" name="secondary_contact_no" value={regData.secondary_contact_no} onChange={handleChange} required />
        </div>

        <div className="registration-form-group">
          <label>Relationship with the Contact *</label>
          <input type="text" name="relation_with_secondary_contact" value={regData.relation_with_secondary_contact} onChange={handleChange} required />
        </div>

        <div className="registration-form-group">
          <label>Email *</label>
          <input type="email" name="email" value={regData.email} onChange={handleChange} required />
        </div>
        <div className="registration-form-group">
          <label>Password *</label>
          <div className="password-input-container">
            <input
              type={showPassword ? 'text' : 'password'}
              name="password"
              value={regData.password}
              onChange={handleChange}
              required
            />
            <button
              type="button"
              className="password-toggle-button"
              onClick={() => setShowPassword(!showPassword)}
            >
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
            </button>
          </div>
        </div>

        <div className="registration-form-group">
          <label>CNIC *</label>
          <input type="text" name="cnic" value={regData.cnic} onChange={handleChange} required />
        </div>

        <div className="registration-form-group">
          <label>School *</label>
          <input type="text" name="school" value={regData.school} onChange={handleChange} required />
        </div>

        <div className="registration-form-group">
          <label>Grade / Class</label>
          <input type="text" name="grade" value={regData.grade} onChange={handleChange} />
        </div>

        <div className="registration-form-group">
          <label>Past Volunteering Experience</label>
          <textarea name="past_experience" value={regData.past_experience} onChange={handleChange}></textarea>
        </div>

        <div className="registration-form-group">
          <label>Soft Skills</label>
          <textarea name="soft_skills" value={regData.soft_skills} onChange={handleChange}></textarea>
        </div>

        <div className='registration-volunteer'>
         <div>
            <p>
                <strong>Note:</strong>This payment is only for people who are looking to join our volunteering programs.
                    <br /> <br />For Consultancies , please visit the respective page. 
              </p>
              <br/>
              <strong>Once Our Team approves your payment, you are free  
                join any volunteering programs you want to</strong>
              <br/>
         </div>
         <div className='reg-pay'>
          <p><strong>Jazzcash Account:</strong>0323 8332105</p>
          <p><strong>Account Title:</strong>Muhammad Tahir</p>
          <p><strong>Amount:</strong>Rs.2000</p>
          <br/>
         </div>
        </div>
        <div className="registration-form-group rg-pay">
          <label>Attach Payment Screen-Shot below</label>
          <input type="file" id="paymentSSInput" name="payment_ss" accept="image/*" onChange={handleChange} style={{ display: 'none' }} />
          <div className="register-btn">
            <div>
              <button type="button" className="custom-file-button" onClick={() => handleFileButtonClick('paymentSSInput')}>Choose File</button>
            </div>
            {regData.payment_ssName && <span className="file-name">Selected file: {regData.payment_ssName}</span>}
          </div>
        </div>

        <div className="register-btn">
          <div>
            <button type="submit" className="registration-form-submit">Register</button>
          </div>
        </div>
      </form>

      {isErrorPopupOpen && (
        <PopupMessage message={error} onClose={closeErrorPopup} />
      )}
    </div>
  );
};

export default RegistrationForm;
