// LoadingLine.js
import React from 'react';
import '../styles/loadingSpinner.css';

const LoadingLine = () => {
  return (
    <div className="loading-line-overlay">
      <div className="loading-line"></div>
    </div>
  );
};

export default LoadingLine;
