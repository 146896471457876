import React, { useState, useEffect } from 'react';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import '../styles/catalog.css';
import { fetchGalleries } from '../apiService'; // Import the API function

const BookCatalog = () => {
  const [galleries, setGalleries] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [direction, setDirection] = useState('');
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const getGalleries = async () => {
      try {
        const data = await fetchGalleries();
        setGalleries(data);
      } catch (error) {
        console.error('Error fetching galleries', error);
      }
    };

    getGalleries();
  }, []);

  const itemsPerPage = 1;
  const totalPages = Math.ceil(galleries.length / itemsPerPage);

  const handlePrevPage = () => {
    if (!isAnimating && currentPage > 0) {
      setDirection('left');
      setIsAnimating(true);
      setTimeout(() => {
        setCurrentPage((prevPage) => prevPage - 1);
        setIsAnimating(false);
      }, 500);
    }
  };

  const handleNextPage = () => {
    if (!isAnimating && currentPage < totalPages - 1) {
      setDirection('right');
      setIsAnimating(true);
      setTimeout(() => {
        setCurrentPage((prevPage) => prevPage + 1);
        setIsAnimating(false);
      }, 500);
    }
  };


  return (
    <>
      <Navbar />
      <div className="book-container">
        {galleries.length > 0 && (
          <div className={`book-page ${isAnimating ? (direction === 'right' ? 'enter-from-right' : 'enter-from-left') : 'enter-active'}`}>
            <img src={galleries[currentPage].image_url} alt={galleries[currentPage].title} className="book-image" />
            <div className="book-info">
              <div className='bt-h3-bs'>
                <h3 className="book-title">{galleries[currentPage].title}</h3>
              </div> 
              <p className="book-description">{galleries[currentPage].description}</p>
            </div>
          </div>
        )}
        <div className="book-navigation">
          <button onClick={handlePrevPage} className="book-nav-button" disabled={currentPage === 0 || isAnimating}>
            Previous
          </button>
          <button onClick={handleNextPage} className="book-nav-button" disabled={currentPage === totalPages - 1 || isAnimating}>
            Next
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BookCatalog;
