import React from "react";
import "../styles/homePage.css";

const About = () => {
    return (
        <section className="about" id="about">
            <h2>About Us</h2>
            <p>Khwaab helps underserved students by offering guidance and prospects to flourish intellectually. We aim to reduce inequality and create pathways to success through personalised support and valuable connections.</p>
        </section>
    );
};

export default About;
