import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faMapMarkerAlt, faClock } from '@fortawesome/free-solid-svg-icons';
import * as Accordion from '@radix-ui/react-accordion';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import classNames from 'classnames';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import faq from '../assets/data/faq'; // Adjust the path as necessary
import { submitContactForm } from '../apiService'; // Import the API function
import '../styles/contact.css';

const Contact = () => {
    const [query, setQuery] = React.useState({ name: '', email: '', message: '' });

    const handleChange = (e) => {
        setQuery({ ...query, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const result = await submitContactForm(query);
            if (result.status === 200) {
                alert('Message sent successfully');
            } else {
                alert('Failed to send message');
            }
        } catch (error) {
            alert('An error occurred while sending the message');
        }
    };

    return (
        <>
            <Navbar />
            <div className="contactUsContainer">
                <div className="contactContainer">
                    <div className='contactBox'>
                        <div className="contactInfo">
                            <div className="contactItem">
                                <FontAwesomeIcon icon={faEnvelope} className="contactIcon" />
                                <span>khwaabconnect@gmail.com</span>
                            </div>
                            <div className="contactItem">
                                <FontAwesomeIcon icon={faPhone} className="contactIcon" />
                                <span>+92 323 8332105</span>
                            </div>
                            <div className="contactItem">
                                <FontAwesomeIcon icon={faMapMarkerAlt} className="contactIcon" />
                                <span>Q Block, Johar Town, Lahore</span>
                            </div>
                            <div className="contactItem">
                                <FontAwesomeIcon icon={faClock} className="contactIcon" />
                                <span>Mon - Fri: 9:00 AM - 10:00 PM</span>
                            </div>
                        </div>
                        <div className="contactMap">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13613.159584073761!2d74.25352440845086!3d31.46121068067692!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3919017c393bc139%3A0xa00c1007a0150ac8!2sOman%20Rd%2C%20Block%20Q%20Phase%202%20Johar%20Town%2C%20Lahore%2C%20Punjab%2C%20Pakistan!5e0!3m2!1sen!2s!4v1723921087795!5m2!1sen!2s"
                                width="600" height="350" allowFullScreen="" loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                    <div className='contactBox'>
                        <div className='contact-accordion'>
                            <h3 className='contact-accordion-header'>Frequently Asked Questions</h3>
                            <Accordion.Root className="contact-accordion-root" type="single" defaultValue="item-1" collapsible>
                                {faq.map((item, index) => (
                                    <Accordion.Item key={index} className="contact-accordion-item" value={`item-${index}`}>
                                        <AccordionTrigger>{item.question}</AccordionTrigger>
                                        <AccordionContent>{item.answer}</AccordionContent>
                                    </Accordion.Item>
                                ))}
                            </Accordion.Root>
                        </div>
                        <div className='messageBox'>
                            <h3 className='contact-accordion-header'>Leave Us a Message</h3>
                            <form action="/contact" method="post" className='contactForm'>
                                <input type="text" name="name" placeholder="Your Name" required className='contactInput' onChange={handleChange} />
                                <input type="text" name="email" placeholder="Your Phone No" required className='contactInput' onChange={handleChange} />
                                <textarea name="message" placeholder="Your Message" required className='contactTextArea' onChange={handleChange}></textarea>
                                <div className='applyButton'>
                                    <button type="submit" className='contactButton' onClick={handleSubmit}>Send Message</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

const AccordionTrigger = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
    <Accordion.Header className="contact-accordion-trigger">
        <Accordion.Trigger
            className={classNames('contact-accordion-trigger', className)}
            {...props}
            ref={forwardedRef}
        >
            {children}
            <ChevronDownIcon className="contact-chevron" aria-hidden />
        </Accordion.Trigger>
    </Accordion.Header>
));

const AccordionContent = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
    <Accordion.Content
        className={classNames('contact-accordion-content', className)}
        {...props}
        ref={forwardedRef}
    >
        <div className="contact-accordion-content-text">{children}</div>
    </Accordion.Content>
));

export default Contact;
