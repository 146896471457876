import React, { useState } from 'react';
import '../../styles/admin.css';
import { changePassword } from '../../apiService';
import PopupMessage from '../PopupMessage';

const Password = () => {
  const [popupMessage, setPopupMessage] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'email') setEmail(value);
    if (name === 'newPassword') setNewPassword(value);
    if (name === 'confirmNewPassword') setConfirmNewPassword(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmNewPassword) {
      setPopupMessage('Passwords do not match');
      setIsPopupOpen(true);
      return;
    }
    try {
      await changePassword(email, newPassword);
      setPopupMessage('Password updated successfully');
    } catch (error) {
      setPopupMessage(error.response?.data?.message || 'Error updating password');
    }
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setPopupMessage('');
  };

  return (
    <div className="ad-cp-container">
      <h1 className='ad-cp-ps'>Change Password</h1>
      <form onSubmit={handleSubmit} className='ad-cp-form'>
        <label>Email</label>
        <input
          type="email"
          name="email"
          value={email}
          required
          onChange={handleChange}
        />
        <label>New Password</label>
        <input
          type="password"
          name="newPassword"
          value={newPassword}
          required
          onChange={handleChange}
        />
        <label>Confirm New Password</label>
        <input
          type="password"
          name="confirmNewPassword"
          value={confirmNewPassword}
          required
          onChange={handleChange}
        />
        <button type="submit">Change Password</button>
      </form>
      {isPopupOpen && (
        <PopupMessage message={popupMessage} onClose={closePopup} />
      )}
    </div>
  );
};

export default Password;
