import React, { useState, useEffect } from "react";
import "../styles/homePage.css";
import { useSwipeable } from "react-swipeable";
import Ayesha from "../assets/images/Ayesha.jpeg";
import Tahir from "../assets/images/Tahir.jpg";
import Haziq from "../assets/images/haziq.png";
import Abuzar from "../assets/images/Abuzar.jpeg";
import Ahmad from "../assets/images/Ahmad.jpeg";
import Abdullah from "../assets/images/Abdullah.png";
import Marium from "../assets/images/Marium.png";


const teamMembers = [
    { name: "Haziq Ahmad", role: "Co-Founder", image: Haziq },
    { name: "Muhammad Tahir", role: "Founder", image: Tahir },
    { name: "Ayesha Mujahid", role: "Co-Founder", image: Ayesha },
    { name: "Ahmad Hassan", role: "Executive General", image: Ahmad },
    { name: "Abuzar Imran", role: "Director General", image: Abuzar },
    { name: "Maryam Waheed", role: "Secretary General", image: Marium },
    { name: "Abdullah Hassan", role: "Secretary General", image: Abdullah },
];

const Team = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [visibleMembers, setVisibleMembers] = useState(1);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const updateVisibleMembers = () => {
            const screenWidth = window.innerWidth;
            if (screenWidth >= 1024) {
                setVisibleMembers(3);
                setIsMobile(false);
            } else if (screenWidth >= 768) {
                setVisibleMembers(2);
                setIsMobile(false);
            } else {
                setVisibleMembers(1);
                setIsMobile(true);
            }
        };

        updateVisibleMembers();
        window.addEventListener("resize", updateVisibleMembers);

        return () => window.removeEventListener("resize", updateVisibleMembers);
    }, []);

    const prevMember = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? teamMembers.length - visibleMembers : prevIndex - 1
        );
    };

    const nextMember = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === teamMembers.length - visibleMembers ? 0 : prevIndex + 1
        );
    };

    const handlers = useSwipeable({
        onSwipedLeft: nextMember,
        onSwipedRight: prevMember,
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
    });

    return (
        <section className="team-section" id="team">
            <h2 className="team-heading">Our Team</h2>
            <div className="team-slider" {...handlers}>
                {!isMobile && <button className="team-prev-button" onClick={prevMember}>←</button>}
                <div className="team-members-container">
                    {teamMembers.slice(currentIndex, currentIndex + visibleMembers).map((member, index) => (
                        <div className="team-member" key={index}>
                            <img src={member.image} alt={member.name} className="team-member-image" />
                            <h3 className="team-member-name">{member.name}</h3>
                            <p className="team-member-role">{member.role}</p>
                        </div>
                    ))}
                </div>
                {!isMobile && <button className="team-next-button" onClick={nextMember}>→</button>}
            </div>
            {isMobile && <div className="swipe-text">Swipe left or right to see other team members</div>}
        </section>
    );
};

export default Team;
