import React, { useState, useEffect } from 'react';
import '../../styles/admin.css';
import { fetchInternships, deleteInternship } from '../../apiService';

const Internships = () => {
  const [internships, setInternships] = useState([]);
  const [filter, setFilter] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const getInternships = async () => {
      try {
        const data = await fetchInternships();
        setInternships(data);
      } catch (error) {
        setError('Failed to fetch internships.');
      }
    };

    getInternships();
  }, []);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredInternships = internships.filter(internship =>
    internship.ngo_name.toLowerCase().includes(filter.toLowerCase())
  );

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this internship?')) {
      try {
        await deleteInternship(id);
        setInternships(internships.filter(internship => internship.internship_id !== id));
      } catch (error) {
        setError('Failed to delete internship.');
      }
    }
  };

  return (
    <div className="ad-it-internships">
      <div className='admins-bx'> 
        <h2 className="ad-it-title">Internships</h2>
      </div>
      <div className="ad-it-filter">
        <label htmlFor="filter">Filter by NGO:</label>
        <input
          type="text"
          id="filter"
          value={filter}
          onChange={handleFilterChange}
          placeholder="Enter NGO name"
        />
      </div>
      {error && <div className="ad-it-error-message">{error}</div>}
      <table className="ad-it-table">
        <thead>
          <tr>
            <th>Applicant</th>
            <th>Contact No</th>
            <th>NGO</th>
            <th>Duration</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredInternships.length > 0 ? (
            filteredInternships.map((internship) => (
              <tr key={internship.internship_id}>
                <td>{internship.user_name}</td>
                <td>{internship.user_cnic}</td>
                <td>{internship.ngo_name}</td>
                <td>{internship.ngo_duration}</td>
                <td>
                  <button onClick={() => handleDelete(internship.internship_id)} className="ad-it-delete-button">
                    Delete
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No internships found.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Internships;
