import React, { useState, useEffect } from 'react';
import '../../styles/admin.css';
import { fetchQueries, deleteQuery } from '../../apiService';

const Queries = () => {
  const [queries, setQueries] = useState([]);
  const [expandedQuery, setExpandedQuery] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const getQueries = async () => {
      try {
        const data = await fetchQueries();
        setQueries(data);
      } catch {
        setError('Failed to fetch queries.');
      }
    };

    getQueries();
  }, []);

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this query?')) {
      try {
        await deleteQuery(id);
        setQueries((prev) => prev.filter((query) => query.id !== id));
      } catch {
        setError('Failed to delete query.');
      }
    }
  };

  const handleReadMore = (id) => {
    setExpandedQuery(expandedQuery === id ? null : id);
  };

  return (
    <div className="ad-qr-queries">
      <h2 className="ad-qr-title">User Queries</h2>
      {error && <div className="ad-qr-error-message">{error}</div>}
      <table className="ad-qr-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Phone No</th>
            <th>Message</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {queries.map((query, index) => (
            <tr key={index}>
              <td>{query.name}</td>
              <td>{query.email}</td>
              <td>
                <div className={`ad-qr-message ${expandedQuery === query.id ? 'expanded' : ''}`}>
                  {expandedQuery === query.id ? query.message : `${query.message.substring(0, 100)}...`}
                  {query.message.length > 100 && (
                    <button onClick={() => handleReadMore(query.id)} className="ad-qr-read-more">
                      {expandedQuery === query.id ? 'Read less' : 'Read more'}
                    </button>
                  )}
                </div>
              </td>
              <td>
                <button onClick={() => handleDelete(query.id)} className="ad-qr-delete-button">Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Queries;
