import React from "react";
import { Link } from "react-router-dom";
import "../styles/homePage.css";

const Opportunities = () => {
    return (
        <section className="opportunities-section" id="opportunities">
            <h2 className="opportunities-heading">Explore Opportunities</h2>
            <div className="opportunities-list">
                <a href="/ngos" className="opportunity-link-wrapper">
                    <div className="opportunity-item">
                        <h3 className="opportunity-title">Volunteering Opportunities</h3>
                        <div className="hm-op-tst">
                            <p className="opportunity-description">
                                Get a full three-month access to the latest volunteering opportunities and get enrolled at your convenience.
                            </p>
                        </div>
                    </div>
                </a>
                <a href="/consult" className="opportunity-link-wrapper">
                    <div className="opportunity-item">
                        <h3 className="opportunity-title">Counselling Projects</h3>
                        <div className="hm-op-tst">
                            <p className="opportunity-description">
                                Talk to our experienced guides about your ambitions and watch your dreams come to life.
                            </p>
                        </div>
                    </div>
                </a>
            </div>
        </section>
    );
};

export default Opportunities;
