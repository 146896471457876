// src/components/Admins.jsx
import React, { useState, useEffect } from 'react';
import AdminCard from './AdminCard';
import AdminForm from './AdminForm';
 import '../../styles/admin.css';
import { getAdmins} from '../../apiService';

const Admins = () => {
  const [admins, setAdmins] = useState([]);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    // Fetch admins from API
    const fetchAdmins = async () => {
      try {
        const data = await getAdmins();
        setAdmins(data);
      } catch (error) {
        console.error('Error fetching admins:', error);
      }
    };

    fetchAdmins();
  }, []);

 

  
  return (
    <div className="admins">
      <div className="admins-bx">
        <h2>Admins</h2>
        <button onClick={() => setShowForm(true)}>Add Admin</button>      
      
      </div>
      {showForm && (
        <AdminForm 
          onCancel={() => { setShowForm(false)  }}
        />
      )}
      <div className="admin-list">
        {admins.map((admin) => (
          <AdminCard 
            key={admin.cnic}
            admin={admin}
          />
        ))}
      </div>

    </div>
  );
}

export default Admins;
