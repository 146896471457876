const faq=[
  {
      question: "How does Khwaab provide counselling to students?",
      answer: "Khwaab offers counselling to students through structured online meetings, ensuring personalized guidance and support. These sessions are designed to address each student's unique needs, helping them navigate their academic and career paths effectively.",
    },
    {
      "question": "What kind of opportunities does Khwaab offer for internships or volunteering?",
      "answer": "Khwaab offers a diverse range of internships and volunteering opportunities, connecting students with registered NGOs and reputable organizations. These opportunities are carefully curated to align with students' interests and career goals, providing valuable experiences that enhance their profiles for future academic and professional pursuits."
    },
    {
      "question": "Where are you located?",
      "answer": "We are located at 1103 Q Block, Johar town, Lahore"
    },
    {
      "question": "How can students apply for Khwaab’s programs?",
      "answer": "Students can apply for Khwaab's programs by visiting our website, where they can complete the registration form and upload a payment screenshot for volunteering opportunities. Additionally, they can book a consultation appointment by filling out the form on Khwaab's Instagram portal or directly through our website."
    },
    {
      "question": "What is the duration of Khwaab's programs?",
      "answer": "Khwaab's consultancy services span a duration of five months, providing comprehensive guidance throughout this period. In contrast, access to volunteering opportunities is available for a three-month period, offering students a concentrated experience to enhance their profiles."
    }
  

]

export default faq;
