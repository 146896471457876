import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import '../styles/popupMessage.css';

const PopupMessage = ({ message, onClose }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
    return () => setVisible(false);
  }, []);

  const handleClose = () => {
    setVisible(false);
    setTimeout(onClose, 300); // Match the duration with the CSS transition
  };

  return (
    <div className={`popup-overlay ${visible ? 'fade-in' : 'fade-out'}`} onClick={handleClose}>
      <div className={`popup ${visible ? 'slide-in' : 'slide-out'}`} onClick={(e) => e.stopPropagation()}>
        <div className="popup-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
            <path d="M16 8a8 8 0 1 1-16 0 8 8 0 0 1 16 0zM7.002-.004a1 1 0 1 1 2 0l-.001 7a1 1 0 0 1-2 0l-.001-7zm.93 8.42a1.108 1.108 0 1 1 2.216 0 1.108 1.108 0 0 1-2.216 0z"/>
          </svg>
        </div>
        <p>{message}</p>
        <button className="popup-close-button" onClick={handleClose}>Close</button>
      </div>
    </div>
  );
};

PopupMessage.propTypes = {
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PopupMessage;
