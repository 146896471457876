// src/apiService.js
import axios from 'axios';

const API_BASE_URL = 'https://mybackend.khwaabofficial.com/api';

export const getAdmins = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/admins`);
    return response.data;
  } catch (error) {
    console.error('Error fetching admins:', error);
    throw error;
  }
};

export const addAdmin = async (adminData) => {
  const formData = new FormData();
  for (const key in adminData) {
    formData.append(key, adminData[key]);
  }

  try {
    const response = await axios.post(`${API_BASE_URL}/admin`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response;
  } catch (error) {
    console.error('Error adding admin:', error);
    throw error;
  }
};
 
export const fetchAdminProfile = async (token) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/admin/get`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching admin profile:', error);
    throw error;
  }
};

export const updateAdminProfile = async (formData, token) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/admin/edit`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating admin profile:', error);
    throw error;
  }
}; 


export const getNgos = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/ngos`);
    return response.data;
  } catch (error) {
    console.error('Error fetching NGOs:', error);
    throw error;
  }
};

export const createNgo = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/ngos`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  } catch (error) {
    console.error('Error creating NGO:', error);
    throw error;
  }
};

export const updateNgo = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/ngos/edit`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating NGO:', error);
    throw error;
  }
};

export const deleteNgo = async (id) => {
  try {
    await axios.post(`${API_BASE_URL}/ngos/delete`, { id });
  } catch (error) {
    console.error('Error deleting NGO:', error);
    throw error;
  }
};


export const loginUser = async (loginData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/login`, loginData);
    return response.data;
  } catch (error) {
    console.error('Error logging in:', error);
    throw error;
  }
};


export const registerUser = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/register`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response;
  } catch (error) {
    console.error('Error during registration:', error);
    throw error;
  }
};


export const fetchInternshipDetails = async (id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/ngos/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching internship details:', error);
    throw error;
  }
};

export const applyForInternship = async (formData, token) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/applications`, formData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.error('Error submitting application:', error);
    throw error;
  }
}

export const submitContactForm = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/contact`, formData);
    return response;
  } catch (error) {
    console.error('Error submitting contact form:', error);
    throw error;
  }
};
export const fetchGalleries = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/gallery`);
    return response.data;
  } catch (error) {
    console.error('Error fetching galleries:', error);
    throw error;
  }
};



export const fetchUsers = async (token) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/users`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export const updateUserApproval = async (cnic, approved, token) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/user/approve`, 
      { cnic, approved }, 
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response;
  } catch (error) {
    console.error('Error updating approval status:', error);
    throw error;
  }
};


export const fetchInternships = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/applications`);
    return response.data;
  } catch (error) {
    console.error('Error fetching internships:', error);
    throw error;
  }
};

export const deleteInternship = async (id) => {
  try {
    await axios.delete(`${API_BASE_URL}/applications/${id}`);
  } catch (error) {
    console.error('Error deleting internship:', error);
    throw error;
  }
};

export const fetchConsultancies = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/consultancies`);
    return response.data;
  } catch (error) {
    console.error('Error fetching consultancies:', error);
    throw error;
  }
};

export const deleteConsultancy = async (id) => {
  try {
    await axios.delete(`${API_BASE_URL}/consultancies/${id}`);
  } catch (error) {
    console.error('Error deleting consultancy:', error);
    throw error;
  }
};



export const addGallery = async (formData) => {
  try {
    await axios.post(`${API_BASE_URL}/gallery`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  } catch (error) {
    console.error('Error adding gallery:', error);
    throw error;
  }
};

export const updateGallery = async (formData) => {
  try {
    await axios.post(`${API_BASE_URL}/gallery/edit`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  } catch (error) {
    console.error('Error updating gallery:', error);
    throw error;
  }
};

export const deleteGallery = async (id) => {
  try {
    await axios.post(`${API_BASE_URL}/gallery/delete`, { id });
  } catch (error) {
    console.error('Error deleting gallery:', error);
    throw error;
  }
};

export const fetchQueries = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/queries`);
    console.log('Check this',response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching queries:', error);
    throw error;
  }
};

export const deleteQuery = async (id) => {
  try {
    await axios.delete(`${API_BASE_URL}/queries/${id}`);
  } catch (error) {
    console.error('Error deleting query:', error);
    throw error;
  }
};


export const changePassword = async (email, newPassword) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/changePassword`, {
      email,
      newPassword,
    });
    return response.data;
  } catch (error) {
    console.error('Error changing password:', error);
    throw error;
  }
};

export const fetchUserProfile = async (token) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/user/profile`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching user profile:', error);
    throw error;
  }
};

export const updateUserProfile = async (formData, token) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/user/profile`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating user profile:', error);
    throw error;
  }
};

export const fetchUserInternships = async (token) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/user/internships`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching user internships:', error);
    throw error;
  }
};
// Function to update payment screenshot
export const updatePaymentScreenshot = async (formData, token) => {
  await axios.post(`${API_BASE_URL}/user/payment-screenshot`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`
    }
  });
};

export const bookConsultant = async () => {
  try {
    const token = localStorage.getItem('token'); // Assuming the token is stored in local storage
    const response = await axios.post(`${API_BASE_URL}/bookConsultant`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error booking consultant:', error.response || error);
    throw error.response ? error.response.data : new Error('Unknown error');
  }
};