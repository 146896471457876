// Sidebar.jsx

import React from 'react';
import '../../styles/admin.css';

const Sidebar = ({ onSelectOption }) => {
  
  const handleOptionClick = (option) => {
    onSelectOption(option);
  };

  return (
    <div className="sidebar">
      <ul>
        <li onClick={() => handleOptionClick('dashboard')}>Dashboard</li>
        <li onClick={() => handleOptionClick('admins')}>Admins</li>
        <li onClick={() => handleOptionClick('users')}>Users</li>
        <li onClick={() => handleOptionClick('ngos')}>Ngos</li>
        <li onClick={() => handleOptionClick('internships')}>Internships</li>
        <li onClick={() => handleOptionClick('galleries')}>Galleries</li>
        <li onClick={() => handleOptionClick('consultancies')}>Consultancies</li>
        <li onClick={() => handleOptionClick('queries')}>Queries</li>
        <li onClick={() => handleOptionClick('password')}>Set Password</li>
      </ul>
    </div>
  );
}

export default Sidebar;
