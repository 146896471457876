import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import HomePage from './pages/homePage';
import NGOsPage from './pages/ngos';
import Consult from './pages/consultancies';
import Contact from './pages/contact';
import RegisterPage from './pages/registration';
import AdminPortalPage from './pages/Admin';
import InternshipDetails from './components/internship';
import ScrollToTopButton from './components/scroller';
import CatalogPage from './pages/GalleryCatalog.js';
import ProtectedRoute from './ProtectedRoute';
import AdminRoute from './AdminRoute';
import UserProfile from './pages/Profile';

const App = () => {
  return (
    <Router>
        <AuthProvider>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/ngos" element={<NGOsPage />} />
          <Route path="/consult" element={<Consult />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/internship/:id" element={<InternshipDetails />} />
          <Route path="/gallery" element={<CatalogPage />} />
          <Route path="/profile" element={<ProtectedRoute element={UserProfile} />} />
          <Route
            path="/admin"
            element={
              <AdminRoute element={AdminPortalPage} />
            }
          />
        </Routes>
        <ScrollToTopButton />
    </AuthProvider>
      </Router>
  );
};

export default App;
