import React, { useState, useEffect } from 'react';
import '../../styles/admin.css';
import { fetchAdminProfile, updateAdminProfile } from '../../apiService';
import PopupMessage from '../PopupMessage';

const Dashboard = () => {
  const [admin, setAdmin] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [updatedAdmin, setUpdatedAdmin] = useState({});
  const [profileImage, setProfileImage] = useState(null);
  const [popupMessage, setPopupMessage] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    fetchAdminProfile(token)
      .then(data => {
        setAdmin(data);
        setUpdatedAdmin(data);
      })
      .catch(error => {
        console.error('Error fetching admin profile:', error);
      });
  }, []);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setProfileImage(files[0]);
    } else {
      setUpdatedAdmin(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', updatedAdmin.name);
    formData.append('gender', updatedAdmin.gender);
    formData.append('contact_no', updatedAdmin.contact_no);
    formData.append('email', updatedAdmin.email);
    formData.append('position', updatedAdmin.position);
    formData.append('password', updatedAdmin.password);

    if (profileImage) {
      formData.append('profile_img', profileImage);
    }

    try {
      const token = localStorage.getItem('token');
      await updateAdminProfile(formData, token);
      setPopupMessage('Profile updated successfully');
      setIsPopupOpen(true);
      fetchAdminProfile(token).then(data => setAdmin(data));
      setIsEditing(false);
    } catch (error) {
      setPopupMessage('Error updating profile');
      setIsPopupOpen(true);
    }
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setPopupMessage('');
  };

  return (
    <>
      <div className="admin-profile-container">
        <h2 className="admin-profile-title">Welcome, {admin.name}</h2>
        <div className="admin-profile-content">
          <div className="admin-profile-section admin-details-section">
            {isEditing ? (
              <form onSubmit={handleFormSubmit} className="admin-profile-form" encType='multipart/form-data'>
                <div className="admin-profile-form-group">
                  <label>Name</label>
                  <input type="text" name="name" value={updatedAdmin.name} onChange={handleInputChange} />
                </div>
                <div className="admin-profile-form-group">
                  <label>Gender</label>
                  <input type="text" name="gender" value={updatedAdmin.gender} onChange={handleInputChange} />
                </div>
                <div className="admin-profile-form-group">
                  <label>Contact No</label>
                  <input type="text" name="contact_no" value={updatedAdmin.contact_no} onChange={handleInputChange} />
                </div>
                <div className="admin-profile-form-group">
                  <label>Email</label>
                  <input type="email" name="email" value={updatedAdmin.email} onChange={handleInputChange} />
                </div>
                <div className="admin-profile-form-group">
                  <label>Position</label>
                  <input type="text" name="position" value={updatedAdmin.position} onChange={handleInputChange} />
                </div>
                <div className="admin-profile-form-group">
                  <label>Change Password</label>
                  <input type="password" name="password" onChange={handleInputChange} />
                </div>
                <div className="admin-profile-form-group">
                  <label>Profile Image</label>
                  <input type="file" name="profile_image" onChange={handleInputChange} />
                </div>
                <div className="admin-profile-form-actions">
                  <button type="submit" className="admin-profile-submit-button">Save</button>
                  <button type="button" className="admin-profile-cancel-button" onClick={() => setIsEditing(false)}>Cancel</button>
                </div>
              </form>
            ) : (
              <div className="admin-details">
                <div className="admin-avatar">
                  <img src={admin.profile_img_url} alt={admin.name} className='profile-img'/>
                </div>
                <div className="admin-info">
                  <table className="admin-info-table">
                    <tbody>
                      <tr>
                        <td><strong>Name:</strong></td>
                        <td>{admin.name}</td>
                      </tr>
                      <tr>
                        <td><strong>Gender:</strong></td>
                        <td>{admin.gender}</td>
                      </tr>
                      <tr>
                        <td><strong>Contact No:</strong></td>
                        <td>{admin.contact_no}</td>
                      </tr>
                      <tr>
                        <td><strong>Email:</strong></td>
                        <td>{admin.email}</td>
                      </tr>
                      <tr>
                        <td><strong>Position:</strong></td>
                        <td>{admin.position}</td>
                      </tr>

                    </tbody>
                  </table>
                  <button className="admin-edit-button" onClick={() => setIsEditing(true)}>Edit Profile</button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      
      {isPopupOpen && (
        <PopupMessage message={popupMessage} onClose={closePopup} />
      )}
    </>
  );
};

export default Dashboard;
