import React, { useEffect, useState, useContext } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import PopupMessage from './PopupMessage';
import LoadingSpinner from './LoadingSpinner';
import '../styles/internshipDetails.css';
import { fetchInternshipDetails, applyForInternship } from '../apiService'; // Import the API functions

const InternshipDetails = () => {
  const navigate = useNavigate();
  const [internship, setInternship] = useState(null);
  const [popupMessage, setPopupMessage] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const getInternshipDetails = async () => {
      try {
        const data = await fetchInternshipDetails(id);
        setInternship(data);
      } catch (error) {
        console.error('Error fetching internship details:', error);
      } finally {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
    };

    getInternshipDetails();
  }, [id]);

  const handleApply = async () => {
    const formData = {
      ngo_id: id,
      user_cnic: user.cnic,
    };

    try {
      const response = await applyForInternship(formData, user.token);

      if (response.status === 200) {
        setPopupMessage('Application Submitted Successfully');
      } else {
        setPopupMessage(response.data.message);
      }
    } catch (error) {
      if(error.response.status === 400) {
        console.log("TEst",error.response.data.message);
        setPopupMessage(error.response.data.message);
      }
       else {
      setPopupMessage('Error applying for internship\nPlease try again later');
      }
    } finally {
      setIsPopupOpen(true);
    }
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setPopupMessage('');
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="internship-details-container">
      <button className="back-link" onClick={() => navigate('/ngos')}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left-circle" viewBox="0 0 16 16">
          <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"/>
        </svg>
        Back to Internships
      </button>
      <div className="internship-details">
        {internship?.picture_url && (
          <div className="internship-image">
            <img src={internship.picture_url} alt="Internship" className="int-img" />
          </div>
        )}
        <div className="internship-info">
          <h1>{internship?.name}</h1>
          <p><strong>Credit Hours:</strong> {internship?.credit_hours}</p>
          <p><strong>Country:</strong> {internship?.location}</p>
          <p><strong>Description:</strong> {internship?.description}</p>
          <p><strong>Duration:</strong> {internship?.duration}</p>
          <p><strong>Requirements:</strong> {internship?.requirement}</p>
        </div>
      </div>
      <div id="apply-btn">
        {user ? (
          <button onClick={handleApply}>Apply Now</button>
        ) : (
          <Link to="/register">
            <button>Login to Apply</button>
          </Link>
        )}
      </div>

      {isPopupOpen && (
        <PopupMessage message={popupMessage} onClose={closePopup} />
      )}
    </div>
  );
};

export default InternshipDetails;
