import React from "react";
import logo from "../assets/images/logo.jpeg";
import "../styles/footer.css";

const Footer = () => {
    return (
        <footer className="footer-section">
            <div className="footer-container">
                <div className="footer-logo">
                    <img src={logo} alt="Khwaab Logo" />
                    <br />

                    <p className="footer-brand">Khwaab</p>
                    <br />
                    <p className="footer-para">
                            Empowering students to shape their future by providing them with the right tools and resources.
                    </p>
                </div>
                <div className="footer-links">
                    <div className="footer-column">
                        <h4 className="footer-heading">Quick Links</h4>
                        <ul className="footer-list">
                            <li className="footer-list-item">
                                <a href="/" className="footer-link">Home</a>
                            </li>
                            <li className="footer-list-item">
                                <a href="/ngos" className="footer-link">Volunteer</a>
                            </li>
                            <li className="footer-list-item">
                                <a href="/consult" className="footer-link">Counselling</a>
                            </li>
                            <li className="footer-list-item">
                                <a href="/gallery" className="footer-link">Gallery</a>
                            </li>
                            <li className="footer-list-item">
                                <a href="/register" className="footer-link">Register</a>
                            </li>
                        </ul>
                    </div>
                  
              
                    <div className="footer-column">
              
                        <h4 className="footer-heading">Our Locations</h4>
                        <ul className="footer-list">
                            <li className="footer-list-item">Lahore</li>
                            <li className="footer-list-item">Gujrat</li>
                            <li className="footer-list-item">Quetta</li>
                        </ul>
                    </div>
                    <div className="footer-column">
                        <h4 className="footer-heading">Contact Info</h4>
                        <ul className="footer-list">
                            <li className="footer-list-item">Email: khwaabconnect@gmail.com</li>
                            <li className="footer-list-item">Phone: +92 323 8332105</li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
