import React, { useState, useEffect } from 'react';
import '../../styles/admin.css';
import { fetchGalleries, addGallery, updateGallery, deleteGallery } from '../../apiService';

const Galleries = () => {
  const [galleries, setGalleries] = useState([]);
  const [newGallery, setNewGallery] = useState({ image: null, description: '', title: '' });
  const [editingGallery, setEditingGallery] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedDescription, setSelectedDescription] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const getGalleries = async () => {
      try {
        const data = await fetchGalleries();
        setGalleries(data);
      } catch {
        setError('Failed to fetch galleries.');
      }
    };

    getGalleries();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewGallery((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewGallery((prev) => ({ ...prev, image: file }));
    }
  };

  const handleAddGallery = async () => {
    const formData = new FormData();
    formData.append('image', newGallery.image);
    formData.append('description', newGallery.description);
    formData.append('title', newGallery.title);

    try {
      await addGallery(formData);
      setNewGallery({ image: null, description: '', title: '' }); // Reset form
      const data = await fetchGalleries(); // Refresh galleries list
      setGalleries(data);
    } catch {
      setError('Failed to add gallery.');
    }
  };

  const handleDeleteGallery = async (id) => {
    if (window.confirm('Are you sure you want to delete this gallery?')) {
      try {
        await deleteGallery(id);
        setGalleries((prev) => prev.filter((gallery) => gallery.id !== id)); // Update state
      } catch {
        setError('Failed to delete gallery.');
      }
    }
  };

  const handleEditGallery = (gallery) => {
    setEditingGallery(gallery);
  };

  const handleUpdateGallery = async () => {
    const formData = new FormData();
    formData.append('image', editingGallery.image);
    formData.append('description', editingGallery.description);
    formData.append('title', editingGallery.title);
    formData.append('id', editingGallery.id);

    try {
      await updateGallery(formData);
      setEditingGallery(null); // Close the modal
      const data = await fetchGalleries(); // Refresh galleries list
      setGalleries(data);
    } catch {
      setError('Failed to update gallery.');
    }
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditingGallery((prev) => ({ ...prev, [name]: value }));
  };

  const handleEditFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setEditingGallery((prev) => ({ ...prev, image: file }));
    }
  };

  const handleReadMore = (description) => {
    setSelectedDescription(description);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setSelectedDescription('');
  };

  return (
    <div className="ad-ga-galleries">
      <h2 className="ad-ga-title">Galleries</h2>
      {error && <div className="ad-ga-error-message">{error}</div>}
      <div className="ad-ga-form">
        <label className="ad-ga-label">
          Title
          <input
            type="text"
            name="title"
            value={newGallery.title}
            onChange={handleInputChange}
            className="ad-ga-input"
            required
          />
        </label>
        <label className="ad-ga-label">
          Upload Image
          <input
            type="file"
            name="image"
            onChange={handleFileChange}
            className="ad-ga-input"
            required
          />
        </label>
        <label className="ad-ga-label">
          Description
          <textarea
            name="description"
            value={newGallery.description}
            onChange={handleInputChange}
            className="ad-ga-textarea"
            required
          />
        </label>
        <button onClick={handleAddGallery} className="ad-ga-button">Add Gallery</button>
      </div>
      <div className="ad-ga-list">
        {galleries.map((gallery) => (
          <div key={gallery.id} className="ad-ga-card">
            <img src={gallery.image_url} alt="Gallery" className="ad-ga-image" />
            <div className="ad-ga-card-info">
              <h3 className="ad-ga-card-title"><strong>Title: </strong>{gallery.title}</h3>
              <p className="ad-ga-description">
                <strong>Description: </strong>
                {gallery.description.length > 100 ? (
                  <>
                    {gallery.description.substring(0, 100)}...
                    <button onClick={() => handleReadMore(gallery.description)} className="ad-ga-read-more">Read More</button>
                  </>
                ) : (
                  gallery.description
                )}
              </p>
            </div>
            <div className="ad-ga-card-buttons">
              <button onClick={() => handleEditGallery(gallery)} className="ad-ga-button">Edit</button>
              <button onClick={() => handleDeleteGallery(gallery.id)} className="ad-ga-button">Delete</button>
            </div>
          </div>
        ))}
      </div>

      {editingGallery && (
        <div className="ad-ga-modal">
          <div className="ad-ga-modal-content">
            <h2>Edit Gallery</h2>
            <label className="ad-ga-label">
              Title
              <input
                type="text"
                name="title"
                value={editingGallery.title}
                onChange={handleEditInputChange}
                className="ad-ga-input"
                required
              />
            </label>
            <label className="ad-ga-label">
              Upload Image
              <input
                type="file"
                name="image"
                onChange={handleEditFileChange}
                className="ad-ga-input"
              />
            </label>
            <label className="ad-ga-label">
              Description
              <textarea
                name="description"
                value={editingGallery.description}
                onChange={handleEditInputChange}
                className="ad-ga-textarea"
                required
              />
            </label>
            <button onClick={handleUpdateGallery} className="ad-ga-button">Update Gallery</button>
            <button onClick={() => setEditingGallery(null)} className="ad-ga-button">Cancel</button>
          </div>
        </div>
      )}

      {showPopup && (
        <div className="ad-ga-popup">
          <div className="ad-ga-popup-content">
            <h2>Description</h2>
            <p>{selectedDescription}</p>
            <button onClick={handleClosePopup} className="ad-ga-button">Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Galleries;
