import React, { useContext, useState } from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import classNames from 'classnames';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import '../styles/consultancy.css';
import Tip from '../assets/data/consultGuide';
import { AuthContext } from '../AuthContext';
import { Link } from 'react-router-dom';
import Haziq from '../assets/images/haziq.png'; // Replace with actual image path
import Tahir from '../assets/images/Tahir.jpg'; // Replace with actual image path
import Ayesha from '../assets/images/Ayesha.jpeg'; // Replace with actual image path
import PopupMessage from '../components/PopupMessage';
import { bookConsultant } from '../apiService';

const consultants = [
  {
    id: 1,
    name: "Muhammad Tahir",
    image: Tahir,
  },
  {
    id: 2,
    name: "Haziq Ahmed",
    image: Haziq,
  },
  {
    id: 3,
    name: "Ayesha Mujahid",
    image: Ayesha
  }
];

const Consult = () => {
  const { user } = useContext(AuthContext);
  const [popupMessage, setPopupMessage] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleBookConsultant = async () => {
    try {
      const data = await bookConsultant();
      setPopupMessage(data.message);
    } catch (error) {
      if (error.message === 'Booking already exists') {
        setPopupMessage(error.message);
      } else {
        setPopupMessage('Error booking consultant');
      }
    } finally {
      setIsPopupOpen(true);
    }
  };
  const closePopup = () => {
    setIsPopupOpen(false);
    setPopupMessage('');
  };

  return (
    <>
      <Navbar />
      <div className='consultancy-container'>
        <div className='consultancy-content'>

          <div className='consultancy-main'>
            <div className='cons-adpt'>
              <div className='consultancy-table'>
                <h2 className="consultant-heading">Consultants</h2>
                {consultants.map((consultant) => (
                  <div key={consultant.id} className="consultancy-card">
                    <img src={consultant.image} alt={consultant.name} className="consultancy-image" />
                    <div className='c-n-div'>
                      <h3 className="consultancy-name">{consultant.name}</h3>
                    </div>
                  </div>
                ))}
                <div className="consultancy-button">
                  {user ? (
                    <button onClick={handleBookConsultant}>Book Now</button>
                  ) : (
                    <Link to="/register">
                      <button>Login to Book</button>
                    </Link>
                  )}
                </div>
              </div>
            </div>
            <div className='consultancy-accordion'>
              <h2 className="consultancy-heading">Consultancy Tips</h2>
              <Accordion.Root className="consultancy-accordion-root" type="single" defaultValue="item-1" collapsible>
                {Object.entries(Tip).map(([key, value]) => (
                  <Accordion.Item key={key} className="consultancy-accordion-item" value={key}>
                    <AccordionTrigger>{key}</AccordionTrigger>
                    <AccordionContent>{value.description}</AccordionContent>
                  </Accordion.Item>
                ))}
              </Accordion.Root>
            </div>
          </div>
        </div>
        {isPopupOpen && (
          <PopupMessage message={popupMessage} onClose={closePopup} />
        )}
      </div>
      <Footer />
    </>
  );
};

const AccordionTrigger = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
  <Accordion.Header className="consultancy-accordion-header">
    <Accordion.Trigger
      className={classNames('consultancy-accordion-trigger', className)}
      {...props}
      ref={forwardedRef}
    >
      {children}
      <ChevronDownIcon className="consultancy-chevron" aria-hidden />
    </Accordion.Trigger>
  </Accordion.Header>
));
const AccordionContent = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
  <Accordion.Content
    className={classNames('consultancy-accordion-content', className)}
    {...props}
    ref={forwardedRef}
  >
    <div className="consultancy-accordion-content-text">
      {Array.isArray(children) ? (
        children.map((item, index) => {
          if (item.type === "heading") {
            return <h3 className='acc-head' key={index}>{item.content}</h3>;
          } else if (item.type === "paragraph") {
            return <p key={index}>{item.content}</p>;
          }
          else if(item.type==="break"){
            return <br key={index} />;
          }
           else {
            return null;
          }
        })
      ) : (
        <p>{children}</p>
      )}
    </div>
  </Accordion.Content>
));


export default Consult;
