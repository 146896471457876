import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import '../styles/ngos.css';
import { getNgos } from '../apiService';

const NGOsPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [ngos, setNgos] = useState([]);
  const [visibleCards, setVisibleCards] = useState([]);
  const navigate = useNavigate();
  const observer = useRef(null);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    // Fetch NGOs data using the getNgos function
    const fetchNgosData = async () => {
      try {
        const data = await getNgos();
        setNgos(data);
      } catch (error) {
        console.error('Error fetching NGOs:', error);
      }
    };

    fetchNgosData();

    // Intersection observer logic
    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const id = parseInt(entry.target.getAttribute('data-id'), 10);
            setVisibleCards((prevVisibleCards) => {
              if (!prevVisibleCards.includes(id)) {
                return [...prevVisibleCards, id];
              }
              return prevVisibleCards;
            });
          }
        });
      },
      { threshold: 0.9 }
    );

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    const cards = document.querySelectorAll('.internship-item');
    cards.forEach((card) => {
      observer.current.observe(card);
    });

    return () => {
      const cards = document.querySelectorAll('.internship-item');
      cards.forEach((card) => {
        observer.current.unobserve(card);
      });
    };
  }, [ngos]);

  const handleClick = (id) => {
    navigate(`/internship/${id}`);
  };

  const filteredNgos = ngos.filter((ngo) =>
    ngo.location.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <Navbar />
      <div className="ngos-page">
        <h1>Volunteering Opportunities</h1>
        <input
          type="text"
          placeholder="Search by Location"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <div className="internship-list">
          {filteredNgos.map((ngo, index) => (
            <div
              key={ngo.id}
              className={`internship-item ${
                visibleCards.includes(ngo.id)
                  ? index % 2 === 0
                    ? 'slide-in-left'
                    : 'slide-in-right'
                  : ''
              }`}
              onClick={() => handleClick(ngo.id)}
              data-id={ngo.id}
              style={{ animationDelay: `${index * 0.2}s` }}
            >
              {ngo.picture_url && (
                <div className="internship-item-image">
                  <img src={ngo.picture_url} alt={ngo.name} />
                </div>
              )}
              <div className="internship-item-info">
                <h2>{ngo.name}</h2>
                <p><strong className='ng-p-str'>Description: </strong>{ngo.description}</p>
                <p><strong className='ng-p-str'>Requirement: </strong>{ngo.requirement}</p>
                <p><strong className='ng-p-str'>Location: </strong>{ngo.location}</p>
                <p><strong className='ng-p-str'>Credit Hours: </strong>{ngo.credit_hours}</p>
                <p><strong className='ng-p-str'>Duration: </strong>{ngo.duration}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NGOsPage;
