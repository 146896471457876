// AdminCard.jsx

import React, { useState } from 'react';
import '../../styles/admin.css';
import testImg from '../../assets/images/logo.jpeg';

const AdminCard = ({ admin }) => {


  return (
    <div className="admin-card">
      <div className="admin-avatar">
        <img src={admin.profile_image_url || testImg} alt={admin.name} className="profile-img" />
      </div>
      <div className="admin-info">
        <h3><strong>Name: </strong>{admin.name}</h3>
        <p><strong>CNIC: </strong>{admin.cnic}</p>
        <p><strong>Email: </strong>{admin.email}</p>
        <p><strong>Position: </strong>{admin.position}</p>
        <p><strong>Contact: </strong>{admin.contact_no}</p>
      </div>
  
    </div>
  );
};

export default AdminCard;
