import React, { useState } from 'react';
import '../../styles/admin.css';
import PopupMessage from '../PopupMessage';
import { addAdmin } from '../../apiService'; // Import the API function

const AdminForm = ({ onCancel }) => {
  const [popupMessage, setPopupMessage] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [adminData, setAdminData] = useState({
    email: '',
    password: '',
    description: '',
    position: '',
    contact_no: '',
    name: '',
    cnic: '',
    gender: 'Male',
    profile_img: null, // To hold the selected file
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!adminData.profile_img) {
      setPopupMessage('Profile image is required');
      setIsPopupOpen(true);
      return;
    }
    
    try {
      const result = await addAdmin(adminData);

      if (result.status === 200) {
        setPopupMessage('Admin added successfully');
      } else {
        setPopupMessage('Error adding admin');
      }
    } catch (error) {
      setPopupMessage('Error adding admin');
    } finally {
      setIsPopupOpen(true);
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setAdminData((prevData) => ({
      ...prevData,
      [name]: files ? files[0] : value,
    }));
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setPopupMessage('');
  };

  return (
    <div className="admin-form">
      <h2>Add Admin</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Email:
          <input
            type="email"
            name="email"
            value={adminData.email}
            onChange={handleChange}
            required
          />
        </label>
        <label>Gender:</label>
        <select
          name="gender"
          value={adminData.gender}
          onChange={handleChange}
          required
        >
          <option value="Male">Male</option>
          <option value="Female">Female</option>
          <option value="Other">Other</option>
        </select>
        <label>
          Password:
          <input
            type="password"
            name="password"
            value={adminData.password}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Position:
          <input
            type="text"
            name="position"
            value={adminData.position}
            onChange={handleChange}
          />
        </label>
        <label>
          Description:
          <input
            type="text"
            name="description"
            value={adminData.description}
            onChange={handleChange}
          />
        </label>
        <label>
          Contact Number:
          <input
            type="text"
            name="contact_no"
            value={adminData.contact_no}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Name:
          <input
            type="text"
            name="name"
            value={adminData.name}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          CNIC:
          <input
            type="text"
            name="cnic"
            value={adminData.cnic}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Profile Image:
          <input
            type="file"
            name="profile_img"
            accept="image/*"
            onChange={handleChange}
          />
        </label>

        <div className="form-buttons">
          <button type="submit">Add</button>
          <button type="button" onClick={onCancel}>Cancel</button>
        </div>
      </form>
      {isPopupOpen && (
        <PopupMessage message={popupMessage} onClose={closePopup} />
      )}
    </div>
  );
};

export default AdminForm;
